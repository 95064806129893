import React from "react";
import { MenuItems } from "./TopMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { Sidebar, Menu } from "semantic-ui-react";

function SidebarContainer({
  sidebarOpen,
  target,
  setSidebarOpen,
  actualRole,
  history
}) {
  const handleSelect = (_, { name }) => {
    history.push("/" + name);
    setSidebarOpen(false);
  };

  const currentRoute = history.location.pathname;
  return (
    <Sidebar
      as={Menu}
      visible={sidebarOpen}
      target={target}
      onHide={() => setSidebarOpen(false)}
      animation="push"
      vertical
    >
      <MenuItems
        actualRole={actualRole}
        currentRoute={currentRoute}
        selectNavItem={handleSelect}
        horizontal={false}
      />
    </Sidebar>
  );
}

const mapStateToProps = ({ auth: { role } }) => ({
  actualRole: role
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(React.memo(SidebarContainer));
