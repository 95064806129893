export const SET_LOCATION_FILTER = "UI_SET_LOCATION_FILTER";
export const SET_CATEGORY_FILTER = "UI_SET_CATEGORY_FILTER";
export const SET_TRAININGUNITID_FILTER = "UI_SET_TRAININGUNITID_FILTER";

export const setLocationFilter = value => {
  return { type: SET_LOCATION_FILTER, value };
};

export const setCategoryFilter = value => {
  return { type: SET_CATEGORY_FILTER, value };
};
