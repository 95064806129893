import React from "react";

import "./Spinner.scss";

function Spinner({ additionalClass }) {
  return (
    <span
      className={
        (additionalClass ? additionalClass + " " : "") + "fitKiel-spinner"
      }
    >
      <span className="fitKiel-spinner__dot1" />
      <span className="fitKiel-spinner__dot2" />
      <span className="fitKiel-spinner__dot3" />
    </span>
  );
}

export default React.memo(Spinner);
