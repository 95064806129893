import { ApolloLink } from "apollo-link";
import store from "../../store";
import { receivedToken } from "../../actions/csrf";

const csrfTokenAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const {
      response: { headers }
    } = operation.getContext();

    if (headers) {
      const token = headers.get("X-Refresh-CSRF-Token");

      if (token) {
        store.dispatch(receivedToken(token));
      }
    }

    return response;
  });
});

export default csrfTokenAfterware;
