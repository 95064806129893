import React from "react";
import { Trans } from "react-i18next";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

function Logout({ isLoading, handleLogout }) {
  return (
    <div onClick={handleLogout}>
      <Icon name="sign out" />
      {isLoading ? <Spinner /> : <Trans i18nKey="logout.submit" />}
    </div>
  );
}

Logout.propTypes = {
  isLoading: PropTypes.bool,
  handleLogout: PropTypes.func.isRequired
};

export default Logout;
