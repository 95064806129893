export const SUCCESS = "TOASTR_SUCCESS";
export const ERROR = "TOASTR_ERROR";
export const WARNING = "TOASTR_WARNING";
export const INFO = "TOASTR_INFO";

export const toastError = options => {
  return { type: ERROR, options };
};

export const toastSuccess = options => {
  return { type: SUCCESS, options };
};

export const toastInfo = options => {
  return { type: INFO, options };
};

export const toastWarning = options => {
  return { type: WARNING, options };
};
