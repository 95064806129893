import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { save, load } from "redux-localstorage-simple";

import rootReducer from "../reducers";
import csrfTokenStorage from "./middleware/csrfTokenStorage";
import logger from "./middleware/logger";
import toastr from "./middleware/toastr";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const localStorageOptions = {
  states: ["auth.role", "auth.accountId", "ui"],
  namespace: "fit_kiel_state",
  preloadedState: {
    auth: {
      accountId: null
    }
  },
  disableWarnings: true // Disable warnings it local storage doesn't contain key yet
};

const store = createStore(
  rootReducer,
  load(localStorageOptions),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      save(localStorageOptions),
      logger,
      toastr,
      csrfTokenStorage
    )
  )
);

export default store;
