import React from "react";
import { Header } from "semantic-ui-react";

class ChunkErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error.name === "ChunkLoadError") {
      // Reload the page if a chunk could not be loaded
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Header>
          Es gab einen unerwarteten Fehler. Bitte laden Sie die Seite neu.
        </Header>
      );
    }
    return this.props.children;
  }
}

export default ChunkErrorBoundary;
