import React from "react";
import TopMenu from "./TopMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";

function TopMenuContainer({
  actualRole,
  firstName,
  lastName,
  onSidebarToggle,
  history
}) {
  const handleSelect = (_, { name }) => {
    history.push("/" + name);
  };

  const currentRoute = history.location.pathname;
  return (
    <TopMenu
      actualRole={actualRole}
      currentRoute={currentRoute}
      selectNavItem={handleSelect}
      firstName={firstName}
      lastName={lastName}
      onSidebarToggle={onSidebarToggle}
    />
  );
}

const mapStateToProps = ({ auth: { account, role } }) => ({
  firstName: account.firstName,
  lastName: account.lastName,
  actualRole: role
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(React.memo(TopMenuContainer));
