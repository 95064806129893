import React from "react";
import { Grid, Loader } from "semantic-ui-react";

import "./Loading.scss";

function Loading() {
  return (
    <Grid className="fitKiel-loading" textAlign="center" verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          <Loader active>Anwendung wird geladen...</Loader>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export function LoadingSpinner({ title }) {
  return (
    <div className="fk-loading-wrapper">
      <Loader active>{title}</Loader>
    </div>
  );
}

export default Loading;
