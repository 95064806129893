import React from "react";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import LogoutContainer from "./LogoutContainer";
import {
  isAuthorized,
  ROLE_SYSTEM_ADMINISTRATOR,
  ROLE_MEMBER
} from "../../security/role";

import "./TopMenu.scss";
import { Link } from "react-router-dom";

const Logo = () => (
  <img
    src="/images/logo.svg"
    className="fitKiel-topMenu__logo"
    alt="Fit Kiel"
  />
);

function TopMenu({
  firstName,
  lastName,
  actualRole,
  currentRoute,
  selectNavItem,
  onSidebarToggle
}) {
  const profileDropdown = (
    <div>
      <div className="fitKiel-topMenu-profileItem__name">
        {firstName} {lastName}
      </div>
      {actualRole && (
        <div className="fitKiel-topMenu-profileItem__role">
          <Trans i18nKey={`roles.${actualRole}`} />
        </div>
      )}
    </div>
  );

  return (
    <Menu borderless size="massive" className="fitKiel-topMenu">
      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) ? (
        <Link to="/dashboard">
          <Logo />
        </Link>
      ) : (
        <Logo />
      )}

      <Menu.Item
        className="fitKiel-topMenu__sidebar-toggle"
        onClick={onSidebarToggle}
      >
        <Icon name="sidebar" />
      </Menu.Item>

      <MenuItems
        currentRoute={currentRoute}
        actualRole={actualRole}
        selectNavItem={selectNavItem}
      />

      <Menu.Menu position="right">
        <Dropdown item trigger={profileDropdown}>
          <Dropdown.Menu>
            <Dropdown.Item>
              <LogoutContainer />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}

TopMenu.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  actualRole: PropTypes.string.isRequired
};

export function MenuItems({
  currentRoute,
  actualRole,
  selectNavItem,
  horizontal = true
}) {
  const systemMenu = (
    <div className="fitKiel-topMenu__item">
      <Icon name="wrench" position="left" />
      <Trans i18nKey="topMenu.system" />
    </div>
  );

  return (
    <>
      {isAuthorized(actualRole, [ROLE_MEMBER]) && (
        <Menu.Item
          name="my-profile"
          active={currentRoute.startsWith("/my-profile")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="user outline" position="left" />
          <Trans i18nKey="myProfile.index.header" />
        </Menu.Item>
      )}
      {isAuthorized(actualRole, [ROLE_MEMBER, ROLE_SYSTEM_ADMINISTRATOR]) && (
        <Menu.Item
          name="course-plan"
          active={currentRoute.startsWith("/course-plan")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="list" position="left" />
          <Trans i18nKey="coursePlan.index.header" />
        </Menu.Item>
      )}
      {isAuthorized(actualRole, [ROLE_MEMBER]) && (
        <Menu.Item
          name="my-settings"
          active={currentRoute.startsWith("/my-settings")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="user outline" position="left" />
          <Trans i18nKey="mySettings.index.header" />
        </Menu.Item>
      )}
      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) && (
        <Menu.Item
          name="courses"
          active={currentRoute.startsWith("/courses")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="stopwatch" position="left" />
          <Trans i18nKey="courses.index.header" />
        </Menu.Item>
      )}

      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) && (
        <Menu.Item
          name="members"
          active={currentRoute.startsWith("/members")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="users" position="left" />
          <Trans i18nKey="members.index.header" />
        </Menu.Item>
      )}

      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) && (
        <Menu.Item
          name="trials"
          active={currentRoute.startsWith("/trials")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="heartbeat" position="left" />
          <Trans i18nKey="trials.index.header" />
        </Menu.Item>
      )}

      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) && (
        <Menu.Item
          name="personal-training"
          active={currentRoute.startsWith("/personal-training")}
          onClick={selectNavItem}
          className="fitKiel-topMenu__item"
        >
          <Icon name="street view" position="left" />
          <Trans i18nKey="personalTraining.index.header" />
        </Menu.Item>
      )}

      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) && horizontal && (
        <Dropdown item trigger={systemMenu} className="fitKiel-topMenu__item">
          <Dropdown.Menu>
            <Dropdown.Item
              active={currentRoute.startsWith("/user-accounts")}
              onClick={() => selectNavItem(null, { name: "user-accounts" })}
            >
              <Icon name="user" position="left" />
              <Trans i18nKey="userAccounts.index.header" />
            </Dropdown.Item>
            <Dropdown.Item
              active={currentRoute.startsWith("/app-accounts")}
              onClick={() => selectNavItem(null, { name: "app-accounts" })}
            >
              <Icon name="tablet alternate" position="left" />
              <Trans i18nKey="appAccounts.index.header.title" />
            </Dropdown.Item>
            <Dropdown.Item
              active={currentRoute.startsWith("/locations")}
              onClick={() => selectNavItem(null, { name: "locations" })}
            >
              <Icon name="map" position="left" />
              <Trans i18nKey="locations.index.header" />
            </Dropdown.Item>
            <Dropdown.Item
              active={currentRoute.startsWith("/tariffs")}
              onClick={() => selectNavItem(null, { name: "tariffs" })}
            >
              <Icon name="cart" position="left" />
              <Trans i18nKey="tariffs.index.header" />
            </Dropdown.Item>
            <Dropdown.Item
              active={currentRoute.startsWith("/course-categories")}
              onClick={() => selectNavItem(null, { name: "course-categories" })}
            >
              <Icon name="tags" position="left" />
              <Trans i18nKey="courseCategories.index.header" />
            </Dropdown.Item>
            <Dropdown.Item
              active={currentRoute.startsWith("/activity-log")}
              onClick={() => selectNavItem(null, { name: "activity-log" })}
            >
              <Icon name="clipboard list" position="left" />
              <Trans i18nKey="activityLog.index.header.title" />
            </Dropdown.Item>
            <Dropdown.Item
              active={currentRoute.startsWith("/custom-notifications")}
              onClick={() =>
                selectNavItem(null, { name: "custom-notifications" })
              }
            >
              <Icon name="send" position="left" />
              <Trans i18nKey="customNotifications.index.header" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      {isAuthorized(actualRole, [ROLE_SYSTEM_ADMINISTRATOR]) && !horizontal && (
        <Menu.Item className="fitKiel-topMenu__item">
          <Icon name="wrench" position="left" />
          <Trans i18nKey="topMenu.system" />

          <Menu.Menu>
            <Menu.Item
              active={currentRoute.startsWith("/user-accounts")}
              onClick={() => selectNavItem(null, { name: "user-accounts" })}
            >
              <Icon name="user" position="left" />
              <Trans i18nKey="userAccounts.index.header" />
            </Menu.Item>
            <Menu.Item
              active={currentRoute.startsWith("/app-accounts")}
              onClick={() => selectNavItem(null, { name: "app-accounts" })}
            >
              <Icon name="tablet alternate" position="left" />
              <Trans i18nKey="appAccounts.index.header.title" />
            </Menu.Item>
            <Menu.Item
              active={currentRoute.startsWith("/locations")}
              onClick={() => selectNavItem(null, { name: "locations" })}
            >
              <Icon name="map" position="left" />
              <Trans i18nKey="locations.index.header" />
            </Menu.Item>
            <Menu.Item
              active={currentRoute.startsWith("/tariffs")}
              onClick={() => selectNavItem(null, { name: "tariffs" })}
            >
              <Icon name="cart" position="left" />
              <Trans i18nKey="tariffs.index.header" />
            </Menu.Item>
            <Menu.Item
              active={currentRoute.startsWith("/course-categories")}
              onClick={() => selectNavItem(null, { name: "course-categories" })}
            >
              <Icon name="tags" position="left" />
              <Trans i18nKey="courseCategories.index.header" />
            </Menu.Item>
            <Menu.Item
              active={currentRoute.startsWith("/activity-log")}
              onClick={() => selectNavItem(null, { name: "activity-log" })}
            >
              <Icon name="clipboard list" position="left" />
              <Trans i18nKey="activityLog.index.header.title" />
            </Menu.Item>
            <Menu.Item
              active={currentRoute.startsWith("/custom-notifications")}
              onClick={() =>
                selectNavItem(null, { name: "custom-notifications" })
              }
            >
              <Icon name="send" position="left" />
              <Trans i18nKey="customNotifications.index.header" />
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      )}
    </>
  );
}

export default TopMenu;
