import gql from "graphql-tag";

export default gql`
  query UserAccount($id: UUID!) {
    userAccount(id: $id) {
      id
      emailAddress
      firstName
      lastName
      role
      notificationPreferences {
        receiveEmail
        receivePush
      }
      personalNote
      scuContent {
        scuCreatedAt
        scuTrainer
        scuInput
        birthday
        scuQuestion1
        scuQuestion2
        scuQuestion3
        scuQuestion4
        scuQuestion5
        scuQuestion6
        scuQuestion7
        scuQuestion8
        scuQuestion9
        scuQuestion10
        scuQuestion11
        scuQuestion12
      }
      phoneNumber
      userStatus
    }
  }
`;
