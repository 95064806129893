export const ROLE_SYSTEM_ADMINISTRATOR = "SystemAdministrator";
export const ROLE_MEMBER = "Member";

export const hasRole = (role, requiredRole) => {
  return role === requiredRole;
};

export const isAuthorized = (role, anyOfRequiredRoles = []) => {
  if (anyOfRequiredRoles.length === 0) {
    return true;
  }

  return anyOfRequiredRoles.some(requiredRole => hasRole(role, requiredRole));
};
