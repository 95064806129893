export const REQUEST_TOKEN = "CSRF_REQUEST_TOKEN";
export const RECEIVED_TOKEN = "CSRF_RECEIVED_TOKEN";
export const RESET_TOKEN = "CSRF_RESET_TOKEN";

export const requestToken = () => {
  return { type: REQUEST_TOKEN };
};

export const receivedToken = token => {
  return { type: RECEIVED_TOKEN, token };
};

export const resetToken = token => {
  return { type: RESET_TOKEN };
};
