import * as Sentry from "@sentry/browser";

import { resetToken, receivedToken } from "./csrf";
import { toastTranslated } from "../toastr/toastr";

export const LOGGED_IN = "AUTH_LOGGED_IN";
export const RECEIVED_AUTHED_USER_ACCOUNT = "AUTH_RECEIVED_AUTHED_USER_ACCOUNT";
export const LOGIN_FAILED = "AUTH_LOGIN_FAILED";
export const LOGGED_OUT = "AUTH_LOGGED_OUT";
export const RESET = "AUTH_RESET";
export const RESET_AUTH_STORAGE = "AUTH_RESET_AUTH_STORAGE";
export const AUTHENTICATE_FULLY = "AUTH_AUTHENTICATE_FULLY";

export const loggedIn = (account, csrfToken) => {
  return dispatch => {
    dispatch(receivedAuthedUserAccount(account));
    dispatch(receivedToken(csrfToken));
  };
};

export const receivedAuthedUserAccount = account => {
  Sentry.configureScope(scope => {
    scope.setUser({ email: account.emailAddress });
  });

  return { type: RECEIVED_AUTHED_USER_ACCOUNT, account };
};

export const loginFailed = error => {
  return { type: LOGIN_FAILED, error };
};

export const loggedOut = () => {
  return dispatch => {
    dispatch(reset());
    toastTranslated("success", "logout.events.success");
  };
};

export const authenticateFully = accountId => {
  return { type: AUTHENTICATE_FULLY, accountId };
};

export const invalidate = () => {
  return dispatch => {
    dispatch(reset());
    toastTranslated("warning", "errors.sessionExpired");
  };
};

export const reset = () => {
  return dispatch => {
    dispatch(resetToken());
    dispatch(resetAuthStorage());
  };
};

export const resetAuthStorage = () => {
  return { type: RESET_AUTH_STORAGE };
};
