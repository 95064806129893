import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";

// Support iframe-resizer for embedding the webapp in an iframe
import "iframe-resizer/js/iframeResizer.contentWindow";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./main.scss";

let sentryConfig;

const ignoreErrors = [
  "GraphQL error: auth token invalid",
  "GraphQL error: authentication required",
  "Network error: Failed to fetch"
];

if (window._sentryConfig_ !== undefined) {
  sentryConfig = {
    ...window._sentryConfig_,
    ignoreErrors
  };
} else {
  sentryConfig = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "development",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors
  };
}

if (sentryConfig.dsn) {
  Sentry.init(sentryConfig);

  Sentry.configureScope(scope => {
    scope.setTag("component", "webapp");
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
