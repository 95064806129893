import { ApolloLink } from "apollo-link";

import { getToken } from "../../security/csrf";

const csrfTokenMiddleware = new ApolloLink((operation, forward) => {
  const csrfToken = getToken();

  if (csrfToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "X-CSRF-Token": csrfToken
      }
    }));
  }

  return forward(operation);
});

export default csrfTokenMiddleware;
