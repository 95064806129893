import * as React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import Spinner from "../components/spinner/Spinner";
import { receivedAuthedUserAccount } from "../actions/auth";
import client from "../apollo/client";
import userAccountQuery from "../gql/userAccountQuery";
import { isAuthorized } from "../security/role";
import Forbidden from "../components/layout/Forbidden";
import Layout from "../components/layout/Layout";

const ProtectedRoute = ({
  component: Component,
  requiredRoles = [],
  isAuthenticatedFully,
  accountId,
  actualRole,
  name,
  dispatch,
  location,
  ...rest
}) => {
  if (isAuthenticatedFully) {
    return (
      <Route
        {...rest}
        render={matchProps => {
          return isAuthorized(actualRole, requiredRoles) ? (
            <Layout component={Component} {...matchProps} />
          ) : (
            <Layout component={Forbidden} {...matchProps} />
          );
        }}
      />
    );
  }

  if (!accountId) {
    // Store the original URL to redirect to after login
    sessionStorage.setItem("originalUrl", location.pathname);
    return (
      <Redirect
        to={{
          pathname: "/login"
        }}
      />
    );
  }

  const gqlVariables = {
    id: accountId
  };

  client
    .query({ query: userAccountQuery, variables: gqlVariables })
    .then(result => {
      const {
        data: { userAccount }
      } = result;

      dispatch(receivedAuthedUserAccount(userAccount));
    });

  return <Spinner />;
};

const mapStateToProps = ({
  auth: { isAuthenticatedFully, accountId, role }
}) => ({
  isAuthenticatedFully,
  actualRole: role,
  accountId
});

export default connect(mapStateToProps)(ProtectedRoute);
