import { toast as semanticToast } from "react-semantic-toasts";
import i18n from "../i18n/i18n";

import "./Toastr.scss";

export const toastTranslated = (
  type,
  transKey,
  transValues,
  options,
  onClose,
  onClick
) => {
  const message = i18n.t(transKey, transValues);
  toast(type, message, options, onClose, onClick);
};

const toast = (type, message, options, onClose, onClick) => {
  const defaults = {
    type,
    time: type === "error" ? 0 : 5000,
    description: message
  };

  const newOptions = {
    ...defaults,
    ...options
  };

  semanticToast(newOptions, onClose, onClick);
};

export default toast;
