import React, { Suspense, lazy } from "react";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { SemanticToastContainer } from "react-semantic-toasts";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./i18n/i18n";

import apolloClient from "./apollo/client";
import store from "./store";
import ProtectedRoute from "./routing/ProtectedRoute";
import { ROLE_SYSTEM_ADMINISTRATOR, ROLE_MEMBER } from "./security/role";

import "semantic-ui-theme/dist/semantic.min.css";
import "react-semantic-toasts/styles/react-semantic-alert.css";

import Loading from "./components/spinner/Loading";
import ChunkErrorBoundary from "./components/ChunkErrorBoundary";

// Lazy components for React suspense

const LoginContainer = lazy(() => import("./components/login/LoginContainer"));
const DashboardContainer = lazy(() =>
  import("./components/dashboard/DashboardContainer")
);
const MyProfileContainer = lazy(() =>
  import("./components/myProfile/MyProfileContainer")
);
const AppAccountsContainer = lazy(() =>
  import("./components/appAccounts/AppAccountsContainer")
);
const MembersContainer = lazy(() =>
  import("./components/members/MembersContainer")
);
const LocationsContainer = lazy(() =>
  import("./components/locations/LocationsContainer")
);
const UserAccountsContainer = lazy(() =>
  import("./components/userAccounts/UserAccountsContainer")
);
const CoursePlanContainer = lazy(() =>
  import("./components/coursePlan/CoursePlanContainer")
);
const SetPasswordContainer = lazy(() =>
  import("./components/dmz/SetPasswordContainer")
);
const ForgotPasswordContainer = lazy(() =>
  import("./components/dmz/ForgotPasswordContainer")
);
const CoursesContainer = lazy(() =>
  import("./components/courses/CoursesContainer")
);
const CourseCategoriesContainer = lazy(() =>
  import("./components/courseCategories/CourseCategoriesContainer")
);
const TariffsContainer = lazy(() =>
  import("./components/tariffs/TariffsContainer")
);
const ActivityLogContainer = lazy(() =>
  import("./components/activityLog/ActivityLogContainer")
);
const DefaultRoute = lazy(() => import("./routing/DefaultRoute"));
const MySettingsContainer = lazy(() =>
  import("./components/mySettings/MySettingsContainer")
);
const CustomNotificationsContainer = lazy(() =>
  import("./components/customNotifications/CustomNotificationsContainer")
);
const TrialsContainer = lazy(() =>
  import("./components/trials/TrialsContainer")
);
const PersonalTrainingContainer = lazy(() =>
  import("./components/personalTraining/PersonalTrainingContainer")
);

function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <>
          <SemanticToastContainer position="top-right" />
          <ChunkErrorBoundary>
            <Router>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <ProtectedRoute
                    name="dashboard"
                    path="/dashboard"
                    component={DashboardContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="course-plan"
                    path="/course-plan"
                    component={CoursePlanContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR, ROLE_MEMBER]}
                  />
                  <ProtectedRoute
                    name="my-profile"
                    path="/my-profile"
                    component={MyProfileContainer}
                    requiredRoles={[ROLE_MEMBER]}
                  />
                  <ProtectedRoute
                    name="my-settings"
                    path="/my-settings"
                    component={MySettingsContainer}
                    requiredRoles={[ROLE_MEMBER]}
                  />
                  <ProtectedRoute
                    name="courses"
                    path="/courses"
                    component={CoursesContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="course-categories"
                    path="/course-categories"
                    component={CourseCategoriesContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="tariffs"
                    path="/tariffs"
                    component={TariffsContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="members"
                    path="/members"
                    component={MembersContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="user-accounts"
                    path="/user-accounts"
                    component={UserAccountsContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="locations"
                    path="/locations"
                    component={LocationsContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="app-accounts"
                    path="/app-accounts"
                    component={AppAccountsContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="activity-log"
                    path="/activity-log"
                    component={ActivityLogContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="custom-notifications"
                    path="/custom-notifications"
                    component={CustomNotificationsContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="trials"
                    path="/trials"
                    component={TrialsContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    name="personal-training"
                    path="/personal-training"
                    component={PersonalTrainingContainer}
                    requiredRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
                  />
                  <Route
                    path="/coursePlanPublic"
                    component={CoursePlanContainer}
                  />
                  <Route path="/login" component={LoginContainer} />
                  <Route
                    name="forgot-password"
                    path="/dmz/forgot-password"
                    component={ForgotPasswordContainer}
                  />
                  <Route
                    name="set-password"
                    path="/dmz/set-password/:token"
                    component={SetPasswordContainer}
                  />
                  <Route path="/" component={DefaultRoute} />
                </Switch>
              </Suspense>
            </Router>
          </ChunkErrorBoundary>
        </>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
