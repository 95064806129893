import {
  RECEIVED_AUTHED_USER_ACCOUNT,
  LOGIN_FAILED,
  RESET_AUTH_STORAGE
} from "../actions/auth";

const initialState = {
  role: null,
  account: null,
  accountId: null,
  isAuthenticatedFully: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_AUTHED_USER_ACCOUNT:
      return {
        ...state,
        account: { ...action.account },
        accountId: action.account.id,
        role: action.account.role,
        isAuthenticatedFully: true,
        error: null
      };

    case LOGIN_FAILED:
      return {
        ...state,
        account: null,
        accountId: null,
        role: null,
        isAuthenticatedFully: false,
        error: action.error
      };

    case RESET_AUTH_STORAGE:
      return {
        ...state,
        account: null,
        accountId: null,
        role: null,
        isAuthenticatedFully: false,
        error: null
      };

    default:
      return state;
  }
};
