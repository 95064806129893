import React, { useState, useRef } from "react";

import { Container, Sidebar, Ref } from "semantic-ui-react";

import TopMenuContainer from "../navigation/TopMenuContainer";
import SidebarContainer from "../navigation/SidebarContainer";

import "./Layout.scss";

function Layout({ component: Component, ...props }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const pusherRef = useRef();

  const onSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Sidebar.Pushable>
      <SidebarContainer
        sidebarOpen={sidebarOpen}
        target={pusherRef}
        setSidebarOpen={setSidebarOpen}
      />

      <Ref innerRef={pusherRef}>
        <Sidebar.Pusher dimmed={sidebarOpen}>
          <div className="fitKiel-layout">
            <TopMenuContainer {...props} onSidebarToggle={onSidebarToggle} />
            <div className="fitKiel-main-columns">
              <div className="fitKiel-main-content">
                <Container className="fitKiel-main-container">
                  <Component {...props} />
                </Container>
              </div>
            </div>
          </div>
        </Sidebar.Pusher>
      </Ref>
    </Sidebar.Pushable>
  );
}

export default Layout;
