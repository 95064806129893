import { RECEIVED_TOKEN, RESET_TOKEN } from "../../actions/csrf";

import { setToken, resetToken } from "../../security/csrf";

const csrfTokenStorage = () => {
  return next => action => {
    switch (action.type) {
      case RECEIVED_TOKEN:
        setToken(action.token);

        break;

      case RESET_TOKEN:
        resetToken();

        break;

      default:
    }

    return next(action);
  };
};

export default csrfTokenStorage;
