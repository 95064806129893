import { SUCCESS, ERROR, WARNING, INFO } from "../../actions/toastr";

import toast from "../../toastr/toastr";

const toastr = () => {
  return next => action => {
    switch (action.type) {
      case SUCCESS:
        toast("success", action.options);

        break;

      case ERROR:
        toast("error", action.options);

        break;

      case INFO:
        toast("info", action.options);

        break;

      case WARNING:
        toast("warning", action.options);

        break;

      default:
    }

    return next(action);
  };
};

export default toastr;
