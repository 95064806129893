import React from "react";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import Logout from "./Logout";

import logoutMutation from "../../gql/logoutMutation";
import { loggedOut } from "../../actions/auth";

function LogoutContainer({ dispatch }) {
  const handleLogout = mutateFunc => event => {
    event.preventDefault();

    mutateFunc().then(_ => {
      dispatch(loggedOut());
    });
  };

  return (
    <Mutation mutation={logoutMutation}>
      {(mutateFunc, { loading }) => (
        <Logout handleLogout={handleLogout(mutateFunc)} isLoading={loading} />
      )}
    </Mutation>
  );
}

export default connect()(React.memo(LogoutContainer));
