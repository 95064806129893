import React from "react";
import { Message } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

function Forbidden() {
  const { t } = useTranslation();

  return (
    <Message
      error
      header={t("errors.forbidden.header")}
      content={t("errors.forbidden.content")}
    />
  );
}

export default React.memo(Forbidden);
