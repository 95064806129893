import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, from } from "apollo-link";

import csrfTokenMiddleware from "./middleware/csrfTokenMiddleware";
import csrfTokenAfterware from "./afterware/csrfTokenAfterware";
import store from "../store";
import { invalidate } from "../actions/auth";
import fragmentMatcher from "./fragments/fragmentMatcher";
import { toastTranslated } from "../toastr/toastr";
import requestContextMiddleware from "./middleware/requestContextMiddleware";

const httpLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        console.debug(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        // Catch custom error types (via extension)
        if (extensions && extensions.type) {
          const { type: errorType } = extensions;

          switch (errorType) {
            case "authenticationRequired":
            case "authTokenInvalid":
            case "authTokenExpired":
            case "csrfTokenMissing":
            case "csrfTokenInvalid":
              store.dispatch(invalidate());

              break;

            default:
              toastTranslated("error", "errors.api");
          }
        }
      });
    } else if (networkError) {
      toastTranslated("error", "errors.api");
      console.debug(`[Network error]: Message: ${networkError}`);
    }
  }),
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || "/query"
  })
]);

const client = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  link: from([
    csrfTokenMiddleware,
    requestContextMiddleware,
    csrfTokenAfterware,
    httpLink
  ])
});

export default client;
