import {
  SET_LOCATION_FILTER,
  SET_CATEGORY_FILTER,
  SET_TRAININGUNITID_FILTER
} from "../actions/ui";

const initialState = {
  locationFilter: null,
  categoryFilter: null,
  trainingUnitFilter: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION_FILTER:
      return {
        ...state,
        locationFilter: action.value
      };

    case SET_CATEGORY_FILTER:
      return {
        ...state,
        categoryFilter: action.value
      };

    case SET_TRAININGUNITID_FILTER:
      return {
        ...state,
        trainingUnitFilter: action.value
      };

    default:
      return state;
  }
};
