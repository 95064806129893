import { ApolloLink } from "apollo-link";

const requestContextMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-Request-Context": "WebApp"
    }
  }));

  return forward(operation);
});

export default requestContextMiddleware;
