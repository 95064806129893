const TOKEN_KEY = "csrf_token";

export function setToken(token) {
  try {
    localStorage.setItem(TOKEN_KEY, token);
  } catch (error) {
    throw new Error("storageError");
  }
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function resetToken() {
  localStorage.removeItem(TOKEN_KEY);
}
